//
//  Tooltip
//

// Base
.tooltip {
  .tooltip-inner {
    box-shadow: var(--#{$prefix}tooltip-box-shadow);
  }

  &.tooltop-auto-width {
    .tooltip-inner {
      white-space: nowrap;
      max-width: none;
    }
  }

  &.tooltip-inverse {
    --#{$prefix}tooltip-color: #FFF;
    --#{$prefix}tooltip-bg: #181C32;
  }
}

@if $enable-dark-mode { 
  @include color-mode(dark) {
    .tooltip:not(.tooltip-inverse) {
      --#{$prefix}tooltip-bg: #181C32;
      --#{$prefix}tooltip-box-shadow: #{$tooltip-box-shadow-dark};
    }
  }
}
