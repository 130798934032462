.btn-open__modal {
    position: fixed;
    bottom: 0;
}
.modal-dialog.table {
    max-width: 850px;
}

.modal-dialog.alert {
    max-width: 340px;
    width: 100%;
}


.table-overflow {
    height: 350px;
    overflow-x: scroll;
}

body {
    background: #ffffff !important;
}

.app-header {
    box-shadow: none !important;
}

.app-main {
    // background: #F1F1F2 !important;
    background: #Ffffff !important;
    border-radius: 16px;
    // margin-right: 32px;
    margin-right: 0px;
}

.icon-notification {
    width: 20px;
    min-width: 20px;
    max-width: 20px;
    height: 20px;
    background-color: #F1416C;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
}

.card .card-header {
    min-height: auto;
    margin-bottom: 0;
}
.app-sidebar  {
    border-right: none !important;
}

.breadcrumb-item {
    color:#3F4254;
}

.breadcrumb-dot .breadcrumb-item:after {
    padding-top: 2px;
    width:14px;
    color: red;
    content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMjAgNTEyIj48cGF0aCBmaWxsPSIjQTFBNUI3IiBkPSJNMzEwLjYgMjMzLjRjMTIuNSAxMi41IDEyLjUgMzIuOCAwIDQ1LjNsLTE5MiAxOTJjLTEyLjUgMTIuNS0zMi44IDEyLjUtNDUuMyAwcy0xMi41LTMyLjggMC00NS4zTDI0Mi43IDI1NiA3My40IDg2LjZjLTEyLjUtMTIuNS0xMi41LTMyLjggMC00NS4zczMyLjgtMTIuNSA0NS4zIDBsMTkyIDE5MnoiLz48L3N2Zz4=") !important;
}

.nav-line-tabs .nav-item .nav-link.active, .nav-line-tabs .nav-item.show .nav-link, .nav-line-tabs .nav-item .nav-link:hover:not(.disabled) {
    border-bottom: 2px solid var(--bs-primary);
}

.table {
    overflow: hidden;;
}

.table-responsive {
    // border: 1px solid var(--bs-gray-300);
    // border-radius: 6px;
    padding-bottom: 8px;
    overflow-x: scroll;
}

.border {
    border-color: var(--bs-gray-300) !important;
}

.progress-bar {
    border-radius: 10px;
}

.page-item {
    margin-right: 2px;
}

.card-subtitle {
    color:#3F4254;
}


.modal-dialog.table .modal-content {
    border-radius: 12px;
    overflow: hidden;
}

.table-responsive::-webkit-scrollbar {
    height: 8px;
}

.btn-bg-dark {
    background: #7E8299 !important;

}

.sidebar-logo {
    max-width: 160px;
}

#kt_app_header_container {
    padding-left: 0 !important;
}

.nav-link.active {
    color: var(--bs-primary) !important;
}

.dragscroll {
    overflow: auto;
    width: 1500px;
    margin: 0 auto;
}




.card-container  {

    .card-header {
        background-color: var(--bs-body-bg) !important;
        padding: 0 2.25rem;
        border-radius: 10px 10px 0 0;
    }
    .card-body {
        background-color: var(--bs-body-bg) !important;
        padding: 0 2.25rem;
        border-radius: 0 0 10px 10px;
    }
}

.react-datepicker-wrapper {
    width: calc(100% - 41px);
}

.dropdown-menu__search  {
    left: -43px !important;
    height: 364px;
    overflow-y: auto;
    padding-top: 20px;
    z-index: 99 !important;
}




.react-select__control {
    border: none !important;
    box-shadow: none !important;
    padding: 0;
    min-height: 41px !important;
    &:focus {
        outline: none !important;
    }

    &--is-focused {
        border: none !important;
        outline: none !important;
    }
}


.react-select__dropdown-indicator {
    display: none !important;
}


.react-select__menu {
    left: -41px;
    width: calc(100% + 41px) !important;

}

.react-select__clear-indicator {
    color: #99a1be !important;
}



.react-select__placeholder {
    color: #99a1be !important;
}

.react-select__input-container {
    color: #99a1be !important;
    margin: 0 !important;
}



.react-select__value-container {
    padding: 0 !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    flex-wrap: nowrap !important;
    overflow-x: auto !important;
}
.react-select__single-value {
    color: #99a1be !important;
}

.react-select__multi-value {
    background-color: #f1faff !important;
    min-width: auto !important;
}

.react-select__multi-value__label {
    white-space: nowrap;
    overflow:visible !important;
    text-overflow: clip !important;
    // color: #3e97ff !important;
    //color: transparent!important; // Блюр эффект
    //text-shadow: 0 0 6px #3e97ff !important; // Блюр эффект
}

.react-select__indicator-separator {
    display: none;
}

.react-select__menu {
    padding: 10px 0 !important;
    max-height: 400px;
    overflow-x: auto;
}

.react-select__option {
    white-space: pre-wrap;
    color:  #181C32;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    //color: transparent!important; // Блюр эффект
    //text-shadow: 0 0 6px #000!important; // Блюр эффект

    //&:first-line{
    //    font-size: 12px;
    //    color: #7E8299 !important;
    //    text-shadow: none !important;
    //}
    &:hover {
        background-color: #EEF6FF !important;
        color:  #3E97FF;
    }

    &--is-focused {
        background-color: #EEF6FF !important;
        color:  #3E97FF;
    }

    &--is-selected {
        background-color: #EEF6FF !important;
        color:  #3E97FF !important;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            right: 24px;
            top: calc(50% - 12px);
            width: 24px;
            height: 24px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImFycm93cyI+CjxnIGlkPSJjaGVjayI+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik01LjU5OTk1IDEwQzUuNDY4MzQgMTAuMDAwOCA1LjMzNzg4IDkuOTc1NTQgNS4yMTYwNCA5LjkyNTc4QzUuMDk0MjEgOS44NzYwMSA0Ljk4MzM5IDkuODAyNjggNC44ODk5NSA5LjcxTDAuMjk5OTUxIDUuMDhDMC4xMTE2NDcgNC44OTE3IDAuMDA1ODU5MzcgNC42MzYzMSAwLjAwNTg1OTM4IDQuMzdDMC4wMDU4NTkzOCA0LjEwMzcgMC4xMTE2NDcgMy44NDgzMSAwLjI5OTk1MSAzLjY2QzAuNDg4MjU1IDMuNDcxNyAwLjc0MzY0OSAzLjM2NTkxIDEuMDA5OTUgMy4zNjU5MUMxLjI3NjI1IDMuMzY1OTEgMS41MzE2NSAzLjQ3MTcgMS43MTk5NSAzLjY2TDUuNTk5OTUgNy41NUwxMi4yNyAwLjg3MDAwNEMxMi40NTgzIDAuNjgxNyAxMi43MTM2IDAuNTc1OTEyIDEyLjk3OTkgMC41NzU5MTJDMTMuMjQ2MyAwLjU3NTkxMiAxMy41MDE2IDAuNjgxNyAxMy42OSAwLjg3MDAwNEMxMy44NzgzIDEuMDU4MzEgMTMuOTg0IDEuMzEzNyAxMy45ODQgMS41OEMxMy45ODQgMS44NDYzMSAxMy44NzgzIDIuMTAxNyAxMy42OSAyLjI5TDYuMzA5OTUgOS42N0M2LjEyNDY4IDkuODY3NDMgNS44NzAzMSA5Ljk4NTY1IDUuNTk5OTUgMTBaIiBmaWxsPSIjMjg4NEVGIi8+CjwvZz4KPC9nPgo8L3N2Zz4K');
        }
    }
}





.form-select.form-select-solid {
    background-color: var(--bs-gray-100);
    border-color: var(--bs-gray-100);
    color: var(--bs-gray-700);
    transition: color 0.2s ease;
    .no-icon__control {
        background-color: var(--bs-gray-100);
    }

    .no-icon__option {
        white-space: pre-wrap;
        color:  #181C32;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        &:hover {
            background-color: #EEF6FF !important;
            color:  #3E97FF;
        }

        &--is-focused {
            background-color: #EEF6FF !important;
            color:  #3E97FF;
        }

        &--is-selected {
            background-color: #EEF6FF !important;
            color:  #3E97FF !important;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                right: 5px;
                top: calc(50% - 12px);
                width: 24px;
                height: 24px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImFycm93cyI+CjxnIGlkPSJjaGVjayI+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik01LjU5OTk1IDEwQzUuNDY4MzQgMTAuMDAwOCA1LjMzNzg4IDkuOTc1NTQgNS4yMTYwNCA5LjkyNTc4QzUuMDk0MjEgOS44NzYwMSA0Ljk4MzM5IDkuODAyNjggNC44ODk5NSA5LjcxTDAuMjk5OTUxIDUuMDhDMC4xMTE2NDcgNC44OTE3IDAuMDA1ODU5MzcgNC42MzYzMSAwLjAwNTg1OTM4IDQuMzdDMC4wMDU4NTkzOCA0LjEwMzcgMC4xMTE2NDcgMy44NDgzMSAwLjI5OTk1MSAzLjY2QzAuNDg4MjU1IDMuNDcxNyAwLjc0MzY0OSAzLjM2NTkxIDEuMDA5OTUgMy4zNjU5MUMxLjI3NjI1IDMuMzY1OTEgMS41MzE2NSAzLjQ3MTcgMS43MTk5NSAzLjY2TDUuNTk5OTUgNy41NUwxMi4yNyAwLjg3MDAwNEMxMi40NTgzIDAuNjgxNyAxMi43MTM2IDAuNTc1OTEyIDEyLjk3OTkgMC41NzU5MTJDMTMuMjQ2MyAwLjU3NTkxMiAxMy41MDE2IDAuNjgxNyAxMy42OSAwLjg3MDAwNEMxMy44NzgzIDEuMDU4MzEgMTMuOTg0IDEuMzEzNyAxMy45ODQgMS41OEMxMy45ODQgMS44NDYzMSAxMy44NzgzIDIuMTAxNyAxMy42OSAyLjI5TDYuMzA5OTUgOS42N0M2LjEyNDY4IDkuODY3NDMgNS44NzAzMSA5Ljk4NTY1IDUuNTk5OTUgMTBaIiBmaWxsPSIjMjg4NEVGIi8+CjwvZz4KPC9nPgo8L3N2Zz4K');
            }
        }
    }
}



.no-icon__control {
    border: none !important;
    box-shadow: none !important;
    padding: 0;
    min-height: 41px !important;
    color: #99a1be !important;
    &:focus {
        outline: none !important;
    }

    &--is-focused {
        border: none !important;
        outline: none !important;

    }

    &--menu-is-open {
        .no-icon__indicators {
            transform: rotate(180deg);
        }
    }


}

.no-icon__indicators,.no-icon__indicator {
    color: #99a1be !important;
}

.no-icon__indicators {
    transform: rotate(0deg);
    transition: transform .2s ease-in-out;
}

.no-icon__placeholder {
    color: #99a1be !important;
}

.no-icon__input-container {
    color: #99a1be !important;
}
.no-icon__single-value {
    color: #99a1be !important;
}

.no-icon__indicator-separator {
    display: none;
}

.no-icon__menu {
    padding: 10px 0 !important;
    max-height: 400px;
    overflow-x: auto;
}


.no-icon__option {
    white-space: pre-wrap;
    color:  #181C32;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    &:hover {
        background-color: #EEF6FF !important;
        color:  #3E97FF;
    }

    &--is-focused {
        background-color: #EEF6FF !important;
        color:  #3E97FF;
    }

    &--is-selected {
        background-color: #EEF6FF !important;
        color:  #3E97FF !important;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            right: 5px;
            top: calc(50% - 12px);
            width: 24px;
            height: 24px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImFycm93cyI+CjxnIGlkPSJjaGVjayI+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik01LjU5OTk1IDEwQzUuNDY4MzQgMTAuMDAwOCA1LjMzNzg4IDkuOTc1NTQgNS4yMTYwNCA5LjkyNTc4QzUuMDk0MjEgOS44NzYwMSA0Ljk4MzM5IDkuODAyNjggNC44ODk5NSA5LjcxTDAuMjk5OTUxIDUuMDhDMC4xMTE2NDcgNC44OTE3IDAuMDA1ODU5MzcgNC42MzYzMSAwLjAwNTg1OTM4IDQuMzdDMC4wMDU4NTkzOCA0LjEwMzcgMC4xMTE2NDcgMy44NDgzMSAwLjI5OTk1MSAzLjY2QzAuNDg4MjU1IDMuNDcxNyAwLjc0MzY0OSAzLjM2NTkxIDEuMDA5OTUgMy4zNjU5MUMxLjI3NjI1IDMuMzY1OTEgMS41MzE2NSAzLjQ3MTcgMS43MTk5NSAzLjY2TDUuNTk5OTUgNy41NUwxMi4yNyAwLjg3MDAwNEMxMi40NTgzIDAuNjgxNyAxMi43MTM2IDAuNTc1OTEyIDEyLjk3OTkgMC41NzU5MTJDMTMuMjQ2MyAwLjU3NTkxMiAxMy41MDE2IDAuNjgxNyAxMy42OSAwLjg3MDAwNEMxMy44NzgzIDEuMDU4MzEgMTMuOTg0IDEuMzEzNyAxMy45ODQgMS41OEMxMy45ODQgMS44NDYzMSAxMy44NzgzIDIuMTAxNyAxMy42OSAyLjI5TDYuMzA5OTUgOS42N0M2LjEyNDY4IDkuODY3NDMgNS44NzAzMSA5Ljk4NTY1IDUuNTk5OTUgMTBaIiBmaWxsPSIjMjg4NEVGIi8+CjwvZz4KPC9nPgo8L3N2Zz4K');
        }
    }
}


















.form-select.form-select-solid {
    background-color: var(--bs-gray-100);
    border-color: var(--bs-gray-100);
    color: var(--bs-gray-700);
    transition: color 0.2s ease;
    .modal-select__control {
        background-color: var(--bs-gray-100);
    }

    .modal-select__option {
        white-space: pre-wrap;
        color:  #181C32;
        font-size: 10px;
        font-style: normal;
        line-height: 18px;

        &:first-line{
            font-size: 16px;
            color: #7E8299 !important;
        }

        &:hover {
            background-color: #EEF6FF !important;
            color:  #3E97FF;
        }

        &--is-focused {
            background-color: #EEF6FF !important;
            color:  #3E97FF;
        }

        &--is-selected {
            background-color: #EEF6FF !important;
            color:  #3E97FF !important;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                right: 5px;
                top: calc(50% - 12px);
                width: 24px;
                height: 24px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImFycm93cyI+CjxnIGlkPSJjaGVjayI+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik01LjU5OTk1IDEwQzUuNDY4MzQgMTAuMDAwOCA1LjMzNzg4IDkuOTc1NTQgNS4yMTYwNCA5LjkyNTc4QzUuMDk0MjEgOS44NzYwMSA0Ljk4MzM5IDkuODAyNjggNC44ODk5NSA5LjcxTDAuMjk5OTUxIDUuMDhDMC4xMTE2NDcgNC44OTE3IDAuMDA1ODU5MzcgNC42MzYzMSAwLjAwNTg1OTM4IDQuMzdDMC4wMDU4NTkzOCA0LjEwMzcgMC4xMTE2NDcgMy44NDgzMSAwLjI5OTk1MSAzLjY2QzAuNDg4MjU1IDMuNDcxNyAwLjc0MzY0OSAzLjM2NTkxIDEuMDA5OTUgMy4zNjU5MUMxLjI3NjI1IDMuMzY1OTEgMS41MzE2NSAzLjQ3MTcgMS43MTk5NSAzLjY2TDUuNTk5OTUgNy41NUwxMi4yNyAwLjg3MDAwNEMxMi40NTgzIDAuNjgxNyAxMi43MTM2IDAuNTc1OTEyIDEyLjk3OTkgMC41NzU5MTJDMTMuMjQ2MyAwLjU3NTkxMiAxMy41MDE2IDAuNjgxNyAxMy42OSAwLjg3MDAwNEMxMy44NzgzIDEuMDU4MzEgMTMuOTg0IDEuMzEzNyAxMy45ODQgMS41OEMxMy45ODQgMS44NDYzMSAxMy44NzgzIDIuMTAxNyAxMy42OSAyLjI5TDYuMzA5OTUgOS42N0M2LjEyNDY4IDkuODY3NDMgNS44NzAzMSA5Ljk4NTY1IDUuNTk5OTUgMTBaIiBmaWxsPSIjMjg4NEVGIi8+CjwvZz4KPC9nPgo8L3N2Zz4K');
            }
        }
    }
}



.modal-select__control {
    border: none !important;
    box-shadow: none !important;
    padding: 0;
    min-height: 41px !important;
    color: #99a1be !important;
    &:focus {
        outline: none !important;
    }

    &--is-focused {
        border: none !important;
        outline: none !important;

    }

    &--menu-is-open {
        .modal-select__indicators {
            transform: rotate(180deg);
        }
    }


}

.modal-select__indicators,.modal-select__indicator {
    color: #99a1be !important;
}

.modal-select__indicators {
    transform: rotate(0deg);
    transition: transform .2s ease-in-out;
}

.modal-select__placeholder {
    color: #99a1be !important;
}

.modal-select__input-container {
    color: #99a1be !important;
}
.modal-select__single-value {
    color: #99a1be !important;
}

.modal-select__indicator-separator {
    display: none;
}

.modal-select__menu {
    padding: 10px 0 !important;
    max-height: 400px;
    overflow-x: auto;
}


.modal-select__option {
    white-space: pre-wrap;
    color:  #7E8299 !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    &:first-line{
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #181C32 !important;
    }

    &:hover {
        background-color: #EEF6FF !important;
        color:  #3E97FF;
    }

    &--is-focused {
        background-color: #EEF6FF !important;
        color:  #3E97FF;
    }

    &--is-selected {
        background-color: #EEF6FF !important;
        color:  #3E97FF !important;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            right: 5px;
            top: calc(50% - 12px);
            width: 24px;
            height: 24px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImFycm93cyI+CjxnIGlkPSJjaGVjayI+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik01LjU5OTk1IDEwQzUuNDY4MzQgMTAuMDAwOCA1LjMzNzg4IDkuOTc1NTQgNS4yMTYwNCA5LjkyNTc4QzUuMDk0MjEgOS44NzYwMSA0Ljk4MzM5IDkuODAyNjggNC44ODk5NSA5LjcxTDAuMjk5OTUxIDUuMDhDMC4xMTE2NDcgNC44OTE3IDAuMDA1ODU5MzcgNC42MzYzMSAwLjAwNTg1OTM4IDQuMzdDMC4wMDU4NTkzOCA0LjEwMzcgMC4xMTE2NDcgMy44NDgzMSAwLjI5OTk1MSAzLjY2QzAuNDg4MjU1IDMuNDcxNyAwLjc0MzY0OSAzLjM2NTkxIDEuMDA5OTUgMy4zNjU5MUMxLjI3NjI1IDMuMzY1OTEgMS41MzE2NSAzLjQ3MTcgMS43MTk5NSAzLjY2TDUuNTk5OTUgNy41NUwxMi4yNyAwLjg3MDAwNEMxMi40NTgzIDAuNjgxNyAxMi43MTM2IDAuNTc1OTEyIDEyLjk3OTkgMC41NzU5MTJDMTMuMjQ2MyAwLjU3NTkxMiAxMy41MDE2IDAuNjgxNyAxMy42OSAwLjg3MDAwNEMxMy44NzgzIDEuMDU4MzEgMTMuOTg0IDEuMzEzNyAxMy45ODQgMS41OEMxMy45ODQgMS44NDYzMSAxMy44NzgzIDIuMTAxNyAxMy42OSAyLjI5TDYuMzA5OTUgOS42N0M2LjEyNDY4IDkuODY3NDMgNS44NzAzMSA5Ljk4NTY1IDUuNTk5OTUgMTBaIiBmaWxsPSIjMjg4NEVGIi8+CjwvZz4KPC9nPgo8L3N2Zz4K');
        }
    }
}
























.blur {
    color: transparent !important;
	text-shadow: 0 0 6px #000 !important;
}

// .css-t3ipsp-control {
//     border: none !important;
// }

// .css-1u9des2-indicatorSeparator {
//     display: none;

// }

.card-fixed {
    position: sticky;
    bottom: 0;
    left: calc(var(--bs-app-sidebar-width) + 30px);
    right: 60px;
    border-color: none;
    margin-bottom: 60px;
    margin-left: 30px;
    margin-right: 30px;
    z-index: 4;
}
// .table-header {
//     position: sticky;
//     top: 150px;
//     left: calc(var(--bs-app-sidebar-width) + 30px);
//     right: 60px;
// }

.InovuaReactDataGrid--theme-default-light {
    border: 1px solid #dbdfe9 !important;
    font-size: 11px !important;

}

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header {
    color: var(--bs-text-muted) !important;
}


.InovuaReactDataGrid__header {
    height: 60px !important;
    .InovuaReactDataGrid__column-header__resize-wrapper {
        //height: 60px !important;
        height: 64px !important;
    }
    .InovuaReactDataGrid__column-header__content {
        height: 60px !important;
    }
}

.table__data-grid {
    .InovuaReactDataGrid__row-active-borders.InovuaReactDataGrid__row-active-borders--active.InovuaReactDataGrid__row-active-borders--has-border-bottom {
        height: 63px !important;
        outline: none !important;
        border: none !important;
        display:none !important;
    }

    .InovuaReactDataGrid__row {
        height: 63px !important;
        .InovuaReactDataGrid__row-cell-wrap.InovuaReactDataGrid__row-hover-target {
            height: 63px !important;
            background-color: transparent !important;
        }
        .InovuaReactDataGrid__row-cell-wrap  {
            height: 63px !important;
        }
        .InovuaReactDataGrid__cell {
            height: 63px !important;
        }


    }

    .inovua-react-virtual-list__view-container {
        height: 63px !important;
    }

    .InovuaReactDataGrid__row--focused {
        border: none !important;
    }
}

.inovua-react-toolkit-checkbox.inovua-react-toolkit-checkbox--theme-default-light.inovua-react-toolkit-checkbox--checked .inovua-react-toolkit-checkbox__icon-wrapper {
    fill: #3e97ff !important;
    stroke: #e8e8e8 !important;

}
.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__row--even.InovuaReactDataGrid__row--selected,.InovuaReactDataGrid__row--even.InovuaReactDataGrid__row--hover, .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__row--even.InovuaReactDataGrid__row--selected .InovuaReactDataGrid__row-index-column, .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__row--no-zebra.InovuaReactDataGrid__row--selected, .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__row--no-zebra.InovuaReactDataGrid__row--selected .InovuaReactDataGrid__row-index-column {
    background: #ffffff !important;

}



// .inovua-react-toolkit-checkbox__icon-wrapper {
//     border-radius: 0.45em;
//     overflow: hidden;
//     svg {
//         width: 24px !important;
//         height: 24px !important;
//     }

//     fill {
//         #dbdfe9
//     }
// }

.InovuaReactDataGrid__column-header .InovuaReactDataGrid__box--ellipsis {
    overflow: hidden;
    white-space: wrap;
    text-overflow: none;
}

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__cell--show-border-bottom {
    border-bottom: 1px solid #dbdfe9 !important;
}

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__row--first .InovuaReactDataGrid__cell {
    border-top: 1px solid #dbdfe9 !important;
}

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__header {
    border-bottom: 1px solid #dbdfe9 !important;
}

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__cell--show-border-left {
    border-left: 1px solid #dbdfe9 !important;
}

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header--show-border-left, .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header__resize-wrapper--show-border-left, .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__header-group__title--show-border-left {
    border-left: 1px solid #dbdfe9 !important;
}

.InovuaReactDataGrid > * {
    border-color:#dbdfe9 !important;
}


.app-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.app-content {
    padding-top: 0px !important;
}

.danger {
    border: 2px solid red;
}
.my-search {
    z-index: 4;
}
.my-select {
    z-index: 3;
}
.my-select-url {
    z-index: 4;
}
.my-select-autotarget {
    z-index: 4;
}
.my-select-metrics {
    z-index: 4;
    --bs-form-select-bg-img: none !important;
}
.react-datepicker-popper {
    //z-index: 4 !important;
    z-index: 5 !important;
}

.inovua-react-toolkit-load-mask.inovua-react-toolkit-load-mask--theme-default-light .inovua-react-toolkit-load-mask__background-layer {
    background: #ffffff !important;
    opacity: 0.9 !important;
}

.day-update {
    margin-left: 60px !important;
}

.slide-button-menu {
    right: -60px;
    bottom: 5px;
}
.slide-button-menu-hide {
    right: -30px;
    bottom: 5px;
}

.global-custom-row-gray {
    //background: #9b9b9b !important;
    color: #9b9b9b !important;
}
.global-custom-row-gray .text-dark {
    color: #9b9b9b !important;
}
.global-custom-row-gray .inovua-react-toolkit-checkbox__icon-wrapper svg {
    display: none !important;
}
.global-custom-row {}

//.css-10wo9uf-option:after {
//    content:attr(role);
//    color: gray;
//}

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header__filter-wrapper {
    border: 0 !important;
    background: none !important;
    border-top: 0px solid #e4e3e2 !important;
    padding: 4px !important;
    margin-top: -37px;
}

.InovuaReactDataGrid__column-header__menu-tool .InovuaReactDataGrid__sort-icon--asc, .InovuaReactDataGrid__column-header__menu-tool .InovuaReactDataGrid__sort-icon--desc {
    margin-top: -25px !important;
    margin-right: -5px;
}

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header__filter-settings {
    display: none;
}

.btn-reset {
    margin-left: 10px;
    background-color: rgba(62,151,255,0.85) !important;
    color: #FFFFFF !important;
}

.btn-reset-disabled {
    margin-left: 10px;
    background-color: #F1F1F2 !important;
    color: #A1A5B7 !important;
}

.text-period {
    color: #A1A5B7;
    font-size: 11px;
    font-weight: bold;
}

.py-3 {
    padding-top: 0px !important;
}

.mb-8 {
    margin-bottom: 1rem !important;
}
@media (min-width: 1200px) {
    .mb-xl-6 {
        margin-bottom: 0.75rem !important;
    }
}

@media (min-width: 768px) {
    .mb-md-5 {
        margin-bottom: 0.75rem !important;
    }
}
